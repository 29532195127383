import React from 'react';
import { checkToken } from '../../components/useAuth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function NotFound() {
  const isLoggedIn = checkToken();
  const history = useHistory();

  if (isLoggedIn) {
    history.push('/app');
  }

  if (!isLoggedIn) {
    history.push('/');
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <section
              id="not-found"
              className="center margin-top-50 margin-bottom-25"
            >
              <h2>
                404 <i className="icon-line-awesome-question-circle"></i>
              </h2>
              <p>Were sorry, but the page you were looking for doesnt exist</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
