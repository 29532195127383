const typography = (_palette) => ({
  h1: {
    color: '#000',
    fontWeight: 700,
    fontSize: '24px',
    letterSpacing: 'normal',
    lineHeight: '32px',
  },
  h2: {
    color: '#000',
    fontWeight: 700,
    fontSize: '22px',
    letterSpacing: 'normal',
    lineHeight: '30px',
  },
  h3: {
    color: '#000',
    fontWeight: 700,
    fontSize: '20px',
    letterSpacing: 'normal',
    lineHeight: '28px',
  },
  h4: {
    color: '#000',
    fontWeight: 700,
    fontSize: '16px',
    letterSpacing: 'normal',
    lineHeight: '24px',
  },
  h5: {
    color: '#000',
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: 'normal',
    lineHeight: '20px',
  },
  h6: {
    color: '#000',
    fontWeight: 700,
    fontSize: '12px',
    letterSpacing: 'normal',
    lineHeight: '18px',
  },
  // Subtitle Large
  subtitle1: {
    color: '#000',
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: 'normal',
    lineHeight: '24px',
  },
  // Subtitle Medium
  subtitle2: {
    color: '#000',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: 'normal',
    lineHeight: '20px',
  },
  // Subtitle Small
  subtitle3: {
    color: '#000',
    fontWeight: 500,
    fontSize: '12px',
    letterSpacing: 'normal',
    lineHeight: '18px',
  },
  // Body/Large
  body1: {
    color: '#000',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: 'normal',
    lineHeight: '24px',
  },
  // Body/Medium
  body2: {
    color: '#000',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: 'normal',
    lineHeight: '20px',
  },
  // Body/Small
  body3: {
    color: '#000',
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: 'normal',
    lineHeight: '18px',
  },
  button: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: 'normal',
    lineHeight: '20px',

    // TODO: use this to remove upper case from buttons
    textTransform: 'none',
  },
  caption: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 'normal',
  },
  info: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: 'normal',
    lineHeight: '20px',
  },
  overline: {
    color: '#000',
    fontSize: '10px',
    fontWeight: 400,
    letterSpacing: 'normal',
    lineHeight: '14px',
    textTransform: 'normal',
  },
});

export default typography;
