const MuiList = ({ _palette }) => ({
  styleOverrides: {
    root: {
      paddingTop: '0',
      paddingBottom: '0',
    },
  },
});

export default MuiList;
