import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  skeletonCards: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '24px',
  },

  platformsSkeletonCard: {
    '&.MuiSkeleton-root': {
      borderRadius: '8px !important',
    },
  },
}));

export default function CardSkeleton({ noOfCards = 3 }) {
  const classes = useStyles();
  function numberToArray(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number) || number < 0) {
      console.error('Please provide a valid non-negative number.');
      return;
    }

    // Create an array with length equal to the number
    const resultArray = Array.from({ length: number }, (_, index) => index);

    return resultArray;
  }

  const resultArray = numberToArray(noOfCards);

  return resultArray.map((el, index) => {
    return (
      <Box key={index} className={classes.skeletonCards}>
        <Skeleton
          variant="rectangular"
          className={classes.platformsSkeletonCard}
          width={295}
          height={170}
        />
      </Box>
    );
  });
}
