const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    mlgg: 1325,
    mlg: 1439,
    xl: 1920,
  },
};

export default breakpoints;
