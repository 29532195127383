import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles(theme => ({
  platforms: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flex: 1,
    padding: '24px 32px 0 32px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },

  platformsContent: {
    gap: '42px',
    maxWidth: '1024px',
    width: '98%',
    margin: '0 auto',
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
  },

  platformsLogout: {
    '&.MuiButtonBase-root': {
      width: 'fit-content',
      marginLeft: 'auto',
    },
  },

  platformsContentImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
  platformsInfoTitle: {
    '&.MuiTypography-root': {
      color: theme.palette.textPrimary,
      textAlign: 'center',
    },
  },
  platformsInfoText: {
    '&.MuiTypography-root': {
      color: theme.palette.textSecondary3,
      textAlign: 'center',
      marginTop: '4px',
    },
  },
  platformsCard: {
    width: '295px',
    height: '170px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.borderSubtle1}`,
    padding: '32px',
    position: 'relative',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      '& svg': {
        display: 'block',
      },
    },
  },
  platformsCards: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '24px',
  },
  platformsRedirectIcon: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    display: 'none',
  },
  platformsCardImage: {
    marginBottom: '16px',
  },
  platformsCardText: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.borderSubtle1}`,
    '&.MuiTypography-root': {
      color: theme.palette.textPrimary,
      textAlign: 'center',
      paddingTop: '16px',
    },
  },
  platformsCardNonActive: {
    opacity: 0.4,
    cursor: 'not-allowed',

    '&:hover': {
      boxShadow: 'none',
      '& svg': {
        display: 'none',
      },
    },
  },

  urlModalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '465px',
    background: theme.palette.surfaceWhite,
    border: 0,
    borderRadius: '12px',
    padding: '24px',
    boxShadow:
      '0px 20px 24px -4px rgba(16, 24, 40, 0.10), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
  },

  closeBtn: {
    top: '16px',
    right: '20px',

    '&.MuiButtonBase-root': {
      minWidth: 'fit-content',
      padding: 0,
      marginLeft: 'auto',
      width: 'fit-content',
      display: 'flex',
      position: 'absolute',

      '&:hover': {
        background: 'transparent',
      },
    },
  },

  urlModalTitle: {
    '&.MuiTypography-root': {
      color: theme.palette.textPrimary,
      paddingTop: '8px',
    },
  },
  urlModalText: {
    '&.MuiTypography-root': {
      color: theme.palette.textSecondary3,
      marginTop: '4px',
      paddingBottom: '12px',
      borderBottom: `1px solid ${theme.palette.borderSubtle1}`,
    },
  },

  urlModalLinks: {
    maxHeight: '325px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'none',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '10px',
    },

    '&:hover': {
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'grey',
      },
    },
  },

  urlModalLink: {
    padding: '12px',
    color: theme.palette.textPrimary,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    borderRadius: '8px',
    marginRight: '4px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:hover': {
      background: theme.palette.surfaceBrandSubtle,
      color: theme.palette.textBrand,
    },
  },
}));
