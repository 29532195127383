import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles(theme => ({
  loginHeading: {
    '&.MuiTypography-root': {
      color: theme.palette.textPrimary,
    },
  },

  manageText: {
    '&.MuiTypography-root': {
      color: theme.palette.textSecondary3,
      marginTop: '4px ',
    },
  },

  copyRightText: {
    '&.MuiTypography-root': {
      color: theme.palette.textPlaceholder,
      textAlign: 'center ',
    },
  },

  forgotTextBtn: {
    '&.MuiTypography-root': {
      color: theme.palette.textBrand,
    },
  },

  mainFormLogin: {
    display: 'flex',
    height: '100dvh',
    overflow: 'hidden',
  },

  bannerItem: {
    width: '50%',
  },

  contentItem: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '118px',
    paddingBottom: '53px',
    paddingLeft: '36px',
    paddingRight: '36px',
    overflow: 'auto',
  },

  innerContent: {
    maxWidth: '400px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '87px',
  },

  logoImage: {
    alignSelf: 'center',
  },

  bannerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  welcomeContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '40px',
    flex: 1,
  },

  mainFlexbox: {
    display: 'flex',
    flexDirection: 'column',
  },

  loginHeadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  formSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  wrapperContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '36px',
  },
}));
