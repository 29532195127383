// import { useEffect, useState } from 'react';

// export default function useAuth() {
//   const [isLoggedIn, setIsLoggedIn] = useState(() => {
//     // Check initial authentication state from localStorage
//     const token = localStorage.getItem('accessToken');
//     return !!token;
//   });

//   useEffect(() => {
//     const handleStorageChange = () => {
//       // Update authentication state when localStorage changes
//       const token = localStorage.getItem('accessToken');
//       setIsLoggedIn(!!token);
//     };

//     // Listen for changes in localStorage
//     window.addEventListener('storage', handleStorageChange);

//     return () => {
//       // Clean up event listener
//       window.removeEventListener('storage', handleStorageChange);
//     };
//   }, []);

//   return isLoggedIn;
// }

export const checkToken = () => {
  const token = localStorage.getItem('accessToken');

  if (token) {
    return true;
  }

  return false;
};
