import Joi from 'joi';

//if form attributes have camelCase keys
const errorMessages = {
  sectionsAttributes: 'Section',
  questionStatement: 'Question statement',
  optionsAttributes: 'Question Option',
  reportTitle: 'Report Name',
  sectionTitle: 'Section Name',
  QuestionTitle: 'Question Name',
  questionsAttributes: 'Question',
  reqOfficers: 'Number of Officers',
  optionText: 'Option label',
  questionsIndustryVerticalAttributes: 'Industry Verticals',
  associatedSites: 'Sites',
  timeValue: 'Value',
  rateValue: 'Rate',
  leaveReason: 'Reason',
  pricePerHit: 'Price',
  zoneId: 'Zone',
};

//only for template
const enumTemplateResponseType = {
  text: 0,
  number: 1,
  multiselect: 2,
  datetime: 3,
  radio: 4,
  date: 5,
  imageVideo: 6,
  time: 7,
  dropdown: 8,
};

const contactSchema = () => {
  return Joi.object({
    name: Joi.string()
      .when('_destroy', {
        is: true,
        then: Joi.string().optional().allow('', null),
        otherwise: Joi.string()
          .min(1)
          .max(40)
          .regex(/^(?!.*[.']{2,})(?!^[.'])(?!^[ ])[a-zA-Z.' ]+$/),
      })
      .messages({
        'any.min': 'errors.any.min',
        'any.max': 'errors.any.max',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
        'string.pattern.base': 'errors.notAString',
      }),
    contact: Joi.string()
      .when('_destroy', {
        is: true,
        then: Joi.string().optional().allow('', null),
        otherwise: Joi.required(),
      })
      .regex(/^\+(?:[0-9] ?){6,14}[0-9]$/)
      .messages({
        'string.empty': 'errors.any.required',
        'string.pattern.base': 'errors.string.pattern.base',
      }),
    _destroy: Joi.boolean().optional(),
  });
};
const contactsWithEmergencySchema = () => {
  return Joi.object({
    email: Joi.string()
      .email({ tlds: false })
      .when('_destroy', {
        is: true,
        then: Joi.string().optional().allow('', null),
        otherwise: Joi.string(),
      }) // Specify whether top-level domains are required
      .messages({
        'string.email': 'errors.string.email',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    name: Joi.string()
      .when('_destroy', {
        is: true,
        then: Joi.string().optional().allow('', null),
        otherwise: Joi.string()
          .min(1)
          .max(40)
          .regex(/^(?!.*[.']{2,})(?!^[.'])(?!^[ ])[a-zA-Z.' ]+$/),
      })
      .messages({
        'any.min': 'errors.any.min',
        'any.max': 'errors.any.max',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
        'string.pattern.base': 'errors.notAString',
      }),
    contact: Joi.string()
      .when('_destroy', {
        is: true,
        then: Joi.string().optional().allow('', null),
        otherwise: Joi.required(),
      })
      .regex(/^\+(?:[0-9] ?){6,14}[0-9]$/)
      .messages({
        'string.empty': 'errors.any.required',
        'string.pattern.base': 'errors.string.pattern.base',
      }),
    _destroy: Joi.boolean().optional(),
    isEmergencyContact: Joi.boolean().optional(),
  });
};

const tourTemplateSchema = () => {
  return Joi.object({
    name: Joi.string().exist().messages({
      'string.empty': 'errors.any.required',
    }),
    startTime: Joi.string().exist().messages({
      'string.empty': 'errors.any.required',
    }),
    duration: Joi.string().exist().messages({
      'string.empty': 'errors.any.required',
    }),
    report: Joi.string().exist().messages({
      'string.empty': 'errors.any.required',
    }),
    checkpoints: Joi.array().min(1).messages({
      'array.min': 'errors.any.required',
    }),
    occurances: Joi.object({
      repeatTour: Joi.string().exist().messages({
        'string.empty': 'errors.any.required',
      }),
      repeatAfterTime: Joi.string().exist().messages({
        'string.empty': 'errors.any.required',
      }),
    })
      .allow(null)
      .optional(),
  });
};

export default async function joiValidate(form, field = {}) {
  //templates schemas
  const questionOptionSchema = Joi.object({
    optionText: Joi.string().exist().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    points: Joi.number().messages({
      'number.empty': 'errors.any.empty',
    }),
  });
  const questionSchema = Joi.object({
    questionStatement: Joi.string().exist().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    required: Joi.bool().allow(false).optional(),
    responseType: Joi.number().exist(),
    _destroy: Joi.boolean().optional(),
    optionsAttributes: Joi.array()
      .items(questionOptionSchema)
      .when('_destroy', {
        is: true,
        then: Joi.array().items().allow().optional(),
        otherwise: Joi.array()
          .items()
          .when('responseType', {
            is: Joi.number().valid(
              enumTemplateResponseType.multiselect,
              enumTemplateResponseType.radio,
              enumTemplateResponseType.dropdown
            ),
            then: Joi.array()
              .items()
              .min(2)
              .messages({
                'array.min': 'errors.array.minTwo',
              })
              .custom((value, helpers) => {
                const nonDestroyedObjects = value.filter(obj => !obj._destroy);
                if (nonDestroyedObjects.length < 2) {
                  return helpers.error('array.min', {
                    message: 'errors.array.minTwo',
                  });
                }
                return value;
              }),
            otherwise: Joi.array().items().allow().optional(),
          }),
      }),
    questionsIndustryVerticalAttributes: Joi.array()
      .items(
        Joi.object({
          industryVerticalId: Joi.number().exist(),
          industryVerticalTitle: Joi.string().exist(),
        })
      )
      .min(1)
      .messages({
        'array.min': 'errors.array.min',
      }),
  });
  const sectionSchema = Joi.object({
    title: Joi.string().exist().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    _destroy: Joi.boolean().optional(),
    questionsAttributes: Joi.array()
      .items(questionSchema)
      .when('_destroy', {
        is: true,
        then: Joi.array().items().allow().optional(),
        otherwise: Joi.array()
          .items()
          .min(1)
          .messages({
            'array.min': 'errors.array.min',
          })
          .custom((value, helpers) => {
            const nonDestroyedObjects = value.filter(obj => !obj._destroy);
            if (nonDestroyedObjects.length < 1) {
              return helpers.error('array.min', {
                message: 'errors.array.min',
              });
            }
            return value;
          }),
      }),
  });

  const schema = Joi.object({
    avatarRequired: Joi.boolean(),
    avatar: Joi.string().when('avatarRequired', {
      is: Joi.exist(),
      then: Joi.string().exist().messages({
        'any.required': 'Avatar name is required',
      }),
    }),
    contract: Joi.number().messages({
      'any.required': 'errors.any.required',
      'number.base': 'errors.any.required',
    }),
    site: Joi.number().messages({
      'any.required': 'errors.any.required',
      'number.base': 'errors.any.required',
    }),

    template: Joi.number().messages({
      'any.required': 'errors.any.required',
      'number.base': 'errors.any.required',
    }),

    extraDuties: Joi.array()
      .items(
        Joi.object({
          _destroy: Joi.boolean().optional(),
          dutyType: Joi.when('_destroy', {
            is: true,
            then: Joi.string().optional().allow('', null),
            otherwise: Joi.string(),
          }),
          startsAt: Joi.when('_destroy', {
            is: true,
            then: Joi.string().optional().allow('', null),
            otherwise: Joi.date().required().messages({
              'any.required': 'errors.any.required',
              'date.base': 'errors.any.required',
            }),
          }),

          endsAt: Joi.when('_destroy', {
            is: true,
            then: Joi.string().optional().allow('', null),
            otherwise: Joi.date().required().min(Joi.ref('startsAt')).messages({
              'any.required': 'errors.any.required',
              'date.base': 'errors.any.required',
            }),
          }),
          officerCount: Joi.when('_destroy', {
            is: true,
            then: Joi.string().optional().allow('', null),
            otherwise: Joi.number().required().messages({
              'any.required': 'errors.any.required',
              'number.base': 'errors.any.required',
            }),
          }),
          designation: Joi.when('_destroy', {
            is: true,
            then: Joi.string().optional().allow('', null),
            otherwise: Joi.object()
              .custom((value, helpers) => {
                if (Object.keys(value).length === 0) {
                  return helpers.error('object.empty');
                }
                return value;
              })
              .messages({
                'object.empty': 'errors.any.required',
              }),
          }),
          hourlyRate: Joi.when('_destroy', {
            is: true,
            then: Joi.string().optional().allow('', null),
            otherwise: Joi.number().required().messages({
              'any.required': 'errors.any.required',
              'number.base': 'errors.any.required',
            }),
          }),
          loadManagement: Joi.when('_destroy', {
            is: true,
            then: Joi.string().optional().allow('', null),
            otherwise: Joi.boolean().optional(),
          }),

          visitManagement: Joi.when('_destroy', {
            is: true,
            then: Joi.string().optional().allow('', null),
            otherwise: Joi.boolean().optional(),
          }),
          dutyDays: Joi.when('_destroy', {
            is: true,
            then: Joi.string().optional().allow('', null),
            otherwise: Joi.array()
              .items(Joi.number().integer().min(0).max(6))
              .required(),
          }),
        }) // Ensure at least one non-destroyed extrDuty object
      )
      .min(1),
    countryCode: Joi.string().empty(''),
    address: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    name: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    image: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    attachments: Joi.array().min(1).items(Joi.object({})).messages({
      'any.required': 'errors.any.required',
      'array.base': 'errors.any.required',
      'array.min': 'errors.any.required',
    }),
    zipCode: Joi.number().messages({
      'number.base': 'errors.number.base',
      'number.empty': 'errors.number.empty',
    }),
    postalCode: Joi.string().messages({
      'string.base': 'errors.number.base',
      'any.required': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    sameAsFranchise: Joi.boolean(),
    siteLocation: Joi.object({
      lat: Joi.number().exist(),
      lng: Joi.number().exist(),
    }),
    franchiseLocation: Joi.object({
      lat: Joi.number().exist(),
      lng: Joi.number().exist(),
    }),
    zoneArea: Joi.array()
      .min(1)
      .items(
        Joi.array().items(
          Joi.object({
            lat: Joi.number().exist(),
            lng: Joi.number().exist(),
          })
        )
      )
      .messages({
        'any.required': 'errors.boundry.min',
        'array.base': 'errors.boundry.min',
        'array.min': 'errors.boundry.min',
      }),
    siteArea: Joi.array()
      .min(1)
      .items(
        Joi.array().items(
          Joi.object({
            lat: Joi.number().exist(),
            lng: Joi.number().exist(),
          })
        )
      )
      .messages({
        'any.required': 'errors.boundry.min',
        'array.base': 'errors.boundry.min',
        'array.min': 'errors.boundry.min',
      }),
    franchiseArea: Joi.array()
      .min(1)
      .items(
        Joi.array().items(
          Joi.object({
            lat: Joi.number().exist(),
            lng: Joi.number().exist(),
          })
        )
      )
      .messages({
        'any.required': 'errors.boundry.min',
        'array.base': 'errors.boundry.min',
        'array.min': 'errors.boundry.min',
      }),
    zones: Joi.array()
      .min(1)
      .items(
        Joi.array().items(
          Joi.object({
            lat: Joi.number().exist(),
            lng: Joi.number().exist(),
          })
        )
      )
      .messages({
        'any.required': 'errors.any.required',
        'array.base': 'errors.array.base',
        'array.min': 'errors.array.min',
      }),
    sites: Joi.array()
      .min(1)
      .items(
        Joi.array().items(
          Joi.object({
            lat: Joi.number().exist(),
            lng: Joi.number().exist(),
          })
        )
      )
      .messages({
        'any.required': 'errors.any.required',
        'array.base': 'errors.array.base',
        'array.min': 'errors.array.min',
      }),
    emergencyContacts: Joi.array()
      .items(contactSchema())
      .min(1)
      .messages({
        'array.min': 'errors.array.min',
      })
      .custom((value, helpers) => {
        const nonDestroyedObjects = value.filter(obj => !obj._destroy);
        if (nonDestroyedObjects.length === 0) {
          return helpers.error('array.min', { message: 'errors.array.min' });
        }
        return value;
      }),
    contacts: Joi.array()
      .items(contactsWithEmergencySchema())
      .min(1)
      .messages({
        'array.min': 'errors.array.min',
      })
      .custom((value, helpers) => {
        const nonDestroyedObjects = value.filter(obj => !obj._destroy);
        if (nonDestroyedObjects.length === 0) {
          return helpers.error('array.min', { message: 'errors.array.min' });
        }
        return value;
      }),

    tourTemplate: tourTemplateSchema(),
    tours: Joi.array().items(tourTemplateSchema()).messages({
      'array.min': 'errors.array.min',
    }),

    publishServices: Joi.array().items(
      Joi.object({
        startDate: Joi.string().messages({
          'any.required': 'errors.any.required',
          'string.base': 'errors.any.required',
          'string.empty': 'errors.any.required',
        }),
        endDate: Joi.string().messages({
          'any.required': 'errors.any.required',
          'string.base': 'errors.any.required',
          'string.empty': 'errors.any.required',
        }),
      })
    ),
    timezone: Joi.object().empty('').messages({
      'any.required': 'errors.annualRateIncrease',
    }),

    createExtraDuty: Joi.object({
      dutyDate: Joi.string().messages({
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
      startTime: Joi.string().messages({
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
      endTime: Joi.string().messages({
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
      // site: Joi.object().min(1).message(t('errors.any.required')),
      officersCount: Joi.number().min(1).messages({
        'any.min': 'errors.any.required',
        'number.min': 'errors.any.required',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
      selectedReport: Joi.string().min(1).messages({
        'any.min': 'errors.any.required',
        'array.min': 'errors.any.required',
        'array.base': 'errors.any.required',
        'number.min': 'errors.any.required',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
      officersAssigned: Joi.array().items(
        Joi.object({
          checked: Joi.boolean(),
          amount: Joi.number().when('checked', {
            is: true,
            then: Joi.number()
              .min(field?.amount ?? 1)
              .required(),
            // otherwise: Joi.forbidden(),
          }),
          officer: Joi.object().min(1).required(),
        })
      ),
    }),

    phoneNumber: Joi.string()
      .regex(/^\+(?:[0-9] ?){6,14}[0-9]$/)
      .messages({
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
        'string.pattern.base': 'errors.string.pattern.base',
      }),
    fax: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    licenseNumber: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    priceRequired: Joi.boolean(),
    price: Joi.number().when('priceRequired', {
      is: Joi.exist(),
      then: Joi.number().exist().messages({
        'any.required': 'Price is required',
      }),
    }),
    quantity: Joi.number().when('priceRequired', {
      is: Joi.exist(),
      then: Joi.number().exist().messages({
        'any.required': 'Price is required',
      }),
    }),
    occurrence: Joi.object().min(1).message('errors.any.required'),
    supervisor: Joi.number().allow('', null).messages({
      'number.base': 'errors.dropdown.base',
    }),
    termsRequired: Joi.boolean(),
    terms: Joi.boolean().when('termsRequired', {
      is: Joi.exist(),
      then: Joi.boolean().exist().valid(true).messages({
        'any.required': 'The terms and conditions must be accepted.',
        'any.only': 'Terms must be accepted',
      }),
    }),
    firstName: Joi.string()
      .min(1)
      .max(40)
      .regex(/^(?!.*[.']{2,})(?!^[.'])(?!^[ ])[a-zA-Z.' ]+$/)

      .messages({
        'any.min': 'errors.any.min',
        'any.max': 'errors.any.max',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
        'string.pattern.base': 'errors.notAString',
      }),
    lastName: Joi.string()
      .min(1)
      .max(40)
      .regex(/^(?!.*[.']{2,})(?!^[.'])(?!^[ ])[a-zA-Z.' ]+$/)
      .messages({
        'any.min': 'errors.any.min',
        'any.max': 'errors.any.max',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
        'string.pattern.base': 'errors.notAString',
      }),
    designation: Joi.string()
      .min(1)
      .max(40)
      .regex(/^(?!.*[.']{2,})(?!^[.'])(?!^[ ])[a-zA-Z.' ]+$/)
      .messages({
        'any.min': 'errors.any.min',
        'any.max': 'errors.any.max',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
        'string.pattern.base': 'errors.notAString',
      }),

    city: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    genderRequired: Joi.boolean(),
    gender: Joi.string().when('genderRequired', {
      is: Joi.exist(),
      then: Joi.string().valid('male', 'female', 'other').exist().messages({
        'any.only': 'Invalid gender value',
        'any.required': 'gender is required',
      }),
    }),
    country: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    stateRequired: Joi.boolean(),
    state: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),

    userNameRequired: Joi.boolean(),
    userName: Joi.string().when('userNameRequired', {
      is: Joi.exist(),
      then: Joi.string()
        .min(3)
        .max(20)
        .regex(/^[A-Za-z ]*$/)
        .exist()
        .messages({
          'string.base': 'Please enter valid userName',
          'string.min': 'userName name must be at least 3 characters long',
          'string.max': 'userName name must be at most 20 characters long',
          'any.required': 'userName is required',
        }),
    }),
    email: Joi.string()
      .email({ tlds: false }) // Specify whether top-level domains are required
      .messages({
        'string.email': 'Email format is invalid.',
        'any.required': 'a',
        'string.base': 'b',
        'string.empty': 'Email is required.',
      }),
    primaryEmail: Joi.string()
      .email({ tlds: false }) // Specify whether top-level domains are required
      .messages({
        'string.email': 'errors.string.primaryEmail',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    passwordRequired: Joi.boolean(),
    password: Joi.string().when('passwordRequired', {
      is: Joi.exist(),
      then: Joi.string().required().messages({
        'string.base': 'Please enter valid password',
        'any.required': 'password is required',
      }),
    }),
    passwordConfirmationRequired: Joi.boolean(),
    // passwordConfirmation: Joi.string().when('passwordConfirmationRequired', {
    //   is: Joi.exist(),
    //   then: Joi.string().valid(Joi.ref('password')).exist().messages({
    //     'any.only': 'Passwords must match',
    //     'any.required': 'passwordConfirmation is required',
    //   }),
    // }),
    currentPasswordRequired: Joi.boolean(),
    // currentPassword: Joi.string().when('currentPasswordRequired', {
    //   is: Joi.exist(),
    //   then: Joi.string().exist().messages({
    //     'any.required': 'currentPassword is required',
    //   }),
    // }),
    vehicleIdentificationNumber: Joi.string().messages({
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    makeModelYear: Joi.string().messages({
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    registrationNumber: Joi.string().messages({
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    lastMaintenance: Joi.string()
      // .max(DateTime.now().toFormat('MM/dd/yyyy'))
      .messages({
        'any.min': 'errors.any.min',
        'any.max': 'errors.any.max',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    createdAt: Joi.string().messages({
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    description: Joi.string().min(1).messages({
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    startTime: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    endTime: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    // site: Joi.object().min(1).message(t('errors.any.required')),
    associatedSites: Joi.array().min(1).messages({
      'array.min': 'errors.array.min',
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    title: Joi.string().min(1).messages({
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    sectionsAttributes: Joi.array()
      .items(sectionSchema)
      .min(1)
      .messages({
        'array.min': 'errors.array.min',
      })
      .custom((value, helpers) => {
        const nonDestroyedObjects = value.filter(obj => !obj._destroy);
        if (nonDestroyedObjects.length === 0) {
          return helpers.error('array.min', { message: 'errors.array.min' });
        }
        return value;
      }),
    newPassword: Joi.string()
      .min(8)
      .max(20)
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)
      .messages({
        'string.base': 'errors.string.base',
        'string.min': 'errors.password.string.min',
        'string.max': 'errors.password.string.min',
        'string.pattern.base': 'errors.password.string.pattern.base',
        'any.required': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    passwordConfirmation: Joi.string().valid(Joi.ref('newPassword')).messages({
      'any.only': 'errors.passwordConfirmation.string.only',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    currentPassword: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    questionSchema,
    instructions: Joi.string().min(1).messages({
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    content: Joi.string().min(1).messages({
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    startDate: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    endDate: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    location: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    device: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    checkpointType: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    company: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    propertyName: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    locationSource: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    associatedFranchise: Joi.object().empty('').messages({
      'any.required': 'errors.associatedFranchise',
    }),
    salesPerson: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    stage: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    HubspotMap: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    property: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    pipeline: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    dealOwner: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    companyDomain: Joi.string()
      .pattern(/^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/, {
        name: 'domain',
      })
      .messages({
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
        'string.pattern.base': '{{#label}} fails to match the domain pattern.', // Custom message for pattern failure
      }),
    companyName: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),

    companyIndustry: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    companyOwner: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    numberOfEmployees: Joi.number().greater(0).empty('').messages({
      'number.base': 'Please enter a valid number.',
      'number.empty': 'Number is required.',
      'number.greater': 'Number must be greater than 0.',
    }),
    revenue: Joi.number().greater(0).empty('').messages({
      'number.base': 'Please enter a valid number.',
      'number.empty': 'Number is required.',
      'number.greater': 'Number must be greater than 0.',
    }),
    googleAddress: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    reason: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    leaveReason: Joi.string().max(250).messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    followUpDate: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    dealName: Joi.string().messages({
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    intern: Joi.object()
      .custom((value, helpers) => {
        if (Object.keys(value).length === 0) {
          return helpers.error('object.empty');
        }
        return value;
      })
      .messages({
        'object.empty': 'errors.any.required',
      }),
    templateableType: Joi.string().min(1).messages({
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
    availability: Joi.array()
      .items(
        Joi.object({
          startTime: Joi.string().required(),
          endTime: Joi.string().when('startTime', {
            is: 'none',
            then: Joi.forbidden(),
            otherwise: Joi.string().required(),
          }),
        })
      )
      .messages({
        'any.min': 'errors.any.required',
        'array.min': 'errors.any.required',
        'array.base': 'errors.any.required',
        'number.min': 'errors.any.required',
        'any.required': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    thresholds: Joi.array()
      .items(
        Joi.object({
          timeValue: Joi.number().required().empty('').min(1).max(9999),
        })
      )
      .messages({
        'number.required': 'errors.any.required',
        'number.empty': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),

    breakRules: Joi.array()
      .items(
        Joi.object({
          timeValue: Joi.number().required().empty('').min(1).max(9999),
        })
      )
      .messages({
        'number.required': 'errors.any.required',
        'number.empty': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    runSheetDurations: Joi.array()
      .items(
        Joi.object({
          timeValue: Joi.number().required().empty('').min(1).max(9999),
        })
      )
      .messages({
        'number.required': 'errors.any.required',
        'number.empty': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    shiftHits: Joi.array()
      .items(
        Joi.object({
          value: Joi.number().required().empty('').min(1).max(9999),
        })
      )
      .messages({
        'number.required': 'errors.any.required',
        'number.empty': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    generalServices: Joi.array()
      .items(
        Joi.object({
          rateValue: Joi.number().required().empty('').min(1).max(9999),
        })
      )
      .messages({
        'number.required': 'errors.any.required',
        'number.empty': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    extraServices: Joi.array()
      .items(
        Joi.object({
          rateValue: Joi.number().required().empty('').min(1).max(9999),
        })
      )
      .messages({
        'number.required': 'errors.any.required',
        'number.empty': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    devices: Joi.array()
      .items(
        Joi.object({
          rateValue: Joi.number().required().empty('').min(1).max(9999),
        })
      )
      .messages({
        'number.required': 'errors.any.required',
        'number.empty': 'errors.any.required',
        'string.base': 'errors.any.required',
        'string.empty': 'errors.any.required',
      }),
    zoneId: Joi.string().messages({
      'any.min': 'errors.any.min',
      'any.max': 'errors.any.max',
      'any.required': 'errors.any.required',
      'string.base': 'errors.any.required',
      'string.empty': 'errors.any.required',
    }),
  });

  try {
    const options = {
      errors: {
        wrap: {
          label: '',
        },
      },
      abortEarly: false,
      allowUnknown: true,
    };
    const { error } = await schema.validateAsync(form, options);
    if (error) {
      const errors = {};
      error.details.forEach(detail => {
        const message = detail.message;
        const key = detail.context.key;
        const firstLetterCapitalized =
          key.charAt(0).toUpperCase() + key.slice(1);
        let messageArray = message.split(' ');
        messageArray[0] = errorMessages[key]
          ? errorMessages[key]
          : firstLetterCapitalized;
        messageArray = messageArray.join(' ');
        errors[detail.path] = messageArray;
      });

      return errors;
    }

    return {}; // No errors
  } catch (err) {
    const errors = {};

    err.details.forEach(detail => {
      const message = detail.message;
      let key = detail.context.key;

      key = key.replace(/([A-Z])/g, ' $1');
      const firstLetterCapitalized = key.charAt(0).toUpperCase() + key.slice(1);
      let messageArray = message.split(' ');
      messageArray[0] = errorMessages[detail.context.key]
        ? errorMessages[detail.context.key]
        : firstLetterCapitalized;
      messageArray = messageArray.join(' ');
      errors[detail.path] = messageArray;
    });

    return errors;
  }
}

export const joiValidateErrors = async ({ data, field }) => {
  const errors = await joiValidate(data, field); // e.g; { tours } or { shifts }

  if (errors && Object.keys(errors).length) {
    return errors;
  }

  return null;
};
