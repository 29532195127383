import { Avatar, Box, Button, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import { ReactComponent as LogoutIcon } from '../../assets/svg/logout-icon.svg';
import { ReactComponent as DropDownIcon } from '../../assets/svg/chevron-down.svg';

import React, { useEffect, useRef, useState } from 'react';

import { useStyles } from './accountDropdown';

const AccountDropdown = ({ handleLogout }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(null);

  const dropdownRef = useRef(null);

  // Function to open the dropdown
  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  // Function to close the dropdown
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    const userInfo = localStorage.getItem('userData');
    try {
      const parsedData = JSON.parse(userInfo);
      setUser({ ...parsedData.user });
    } catch (error) {}
  }, []);

  return (
    <>
      <Box
        onClick={handleOpen}
        className={classes.mainBoxWrapper}
        ref={dropdownRef}
      >
        {user?.image ? <Avatar alt={user?.name || ''} src={user?.image} /> : ''}
        <Box className={classes.mainProfile}>
          <Typography className={classes.employeeName} variant="subtitle2">
            {user?.firstName} {user?.lastName}
            <Menu
              anchorEl={anchorEl}
              onClose={e => {
                e.stopPropagation();
                handleClose();
              }}
              open={open}
              classes={{ paper: classes.menuMainWrapper }}
              MenuListProps={{
                className: classes.menuMainWrapper,
              }}
            >
              <Box className={classes.mainAvatarItem}>
                <>
                  {user?.image ? (
                    <Avatar alt={user?.name || ''} src={user?.image} />
                  ) : (
                    ''
                  )}
                </>
                <Box className={classes.dropRight}>
                  <Typography className={classes.userName} variant="subtitle2">
                    {user?.firstName} {user?.lastName}
                  </Typography>
                  <Typography
                    className={classes.userDesignation}
                    variant="body3"
                  >
                    {user?.email}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.dropFooter}>
                <Button
                  startIcon={<LogoutIcon />}
                  variant="onlyText"
                  className={classes.logoutBtn}
                  onClick={handleLogout}
                  disableRipple
                >
                  <Typography
                    className={classes.logoutText}
                    variant="subtitle2"
                  >
                    Logout
                  </Typography>
                </Button>
              </Box>
            </Menu>
          </Typography>

          <Typography className={classes.userDesignation} variant="body3">
            {user?.email}
          </Typography>
        </Box>

        <Box className={classes.svgWrapper}>
          <DropDownIcon />
        </Box>
      </Box>
    </>
  );
};

export default AccountDropdown;
