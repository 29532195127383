const palette = {
  primary: {
    main: '#0084FF',
    light: '#EBF5FF',
    dark: '#005FB8',
    contrastText: '#fff',
  },
  secondary: {
    main: '#98999E',
    light: '#DEE0E4',
    dark: '#171717',
    contrastText: '#fff',
  },
  error: {
    main: '#BD1F21',
    light: '#FFEBEB',
    dark: '#9C191B',
    contrastText: '#fff',
  },
  warning: {
    main: '#D87011',
    light: '#FFE2C7',
    dark: '#A65000',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  info: {
    main: '#0084FF',
    light: '#EBF5FF',
    dark: '#005FB8',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  success: {
    main: '#339F76',
    light: '#E5F5EF',
    dark: '#005938',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
  blue: {
    50: '#f0f0f0',
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#f5f5f5',
    A200: '#eeeeee',
    A400: '#bdbdbd',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fff',
    default: '#fff',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  themePurple: '#E5F5EF',
};

export default palette;
