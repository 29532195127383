import { Box, Button, TextField, Typography } from '@mui/material';
import { InputAdornment } from '@mui/material';

import { ReactComponent as Email } from '../../assets/svg/email-icon.svg';
import { ReactComponent as Logo } from '../../assets/images/platforms/signal-logo.svg';
import { useState } from 'react';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { forgetPassword } from '../../services/auth.service';

import formValidatorJoi from '../../utils/formValidator/formValidator.requiredCheck';
import { useStyles } from './forgotPassword.styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const initialFormData = {
  email: '',
};

const ForgotPassEmail = () => {
  const classes = useStyles();
  const history = useHistory();
  const [formData, setFormData] = useState(initialFormData);
  const [errorMessages, setErrorMessages] = useState({});

  const [disabled, setDisabled] = useState(false);

  const windowLocation = window.location;

  const updateFormHandler = (name, value) => {
    setFormData(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const inputChangedHandler = event => {
    // Get name of changed input, and its corresponding value
    const { name, value } = event.target;
    // Update form state against the target input field
    updateFormHandler(name, value);
  };

  const handleSubmit = async event => {
    // Prevent page reload
    event.preventDefault();
    setDisabled(true);

    const errors = await formValidatorJoi(formData);
    if (errors && Object.keys(errors).length) {
      setErrorMessages(errors);
      setDisabled(false);
      return;
    }

    try {
      const payload = {
        user: {
          redirectUrl: `${windowLocation.origin}`,
          email: formData.email,
        },
      };
      const response = await forgetPassword(payload);

      if (response?.statusCode === 200) {
        history.push('/');
        toast.success(response?.message, {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    } catch (error) {
      /**
       * show error in the corresponding field
       * parse errors in array format and set them in errorMessages
       * setErrorMessages(error)
       */

      setDisabled(false);

      toast.error(error?.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    } finally {
      setDisabled(false);
    }
  };

  // JSX code for login form
  const renderForm = (
    <Box
      component="form"
      onSubmit={handleSubmit}
      className={classes.passwordContent}
    >
      <TextField
        error={errorMessages?.email ? true : false}
        type="text"
        name="email"
        helperText={errorMessages?.email}
        placeholder="Email"
        defaultValue={formData.email}
        onChange={e => inputChangedHandler(e)}
        className={classes.passwordTextField}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Email />
            </InputAdornment>
          ),
        }}
      />

      <Button
        className={classes.passwordloginButton}
        variant="primary"
        type="submit"
        disabled={disabled}
      >
        Continue
      </Button>
    </Box>
  );

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.mainPasswordSection}>
        <Box className={classes.passwordContent}>
          <Box className={classes.passwordLogoImage}>
            <Logo alt="signalLogo" />
          </Box>
          <Box className={classes.passwordTextContent}>
            <Typography
              variant="h1"
              className={classes.passwordTextContentTitle}
            >
              Forgot your Password?
            </Typography>
            <Typography
              className={classes.passwordTextContentDescription}
              variant="body2"
            >
              Please enter your email for the verification process. We will send
              a reset password link to your email.
            </Typography>
          </Box>
          {renderForm}
        </Box>
        <Link to="/">
          <Button variant="tertiaryGrey" className={classes.passwordBackBtn}>
            Back to Login
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default ForgotPassEmail;
