import { Box, Button, Modal, Typography } from '@mui/material';
import ADP from '../../assets/images/platforms/adp.png';
import asana from '../../assets/images/platforms/asana.png';
import signalLogoBlue from '../../assets/images/platforms/blue-signal-logo.svg';
import hubspot from '../../assets/images/platforms/hubspot.png';
import ms365 from '../../assets/images/platforms/ms-365.png';
import signalLogoOrange from '../../assets/images/platforms/orange-signal-logo.svg';
import sage100 from '../../assets/images/platforms/sage-intacct.png';
import screenCloud from '../../assets/images/platforms/screen-cloud.png';
import signalLogo from '../../assets/images/platforms/signal-logo.svg';
import talentReef from '../../assets/images/platforms/talent-reef.png';
import trackTik from '../../assets/images/platforms/track-tik.png';
import { ReactComponent as RedirectIcon } from '../../assets/images/platforms/redirect-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/close-icon.svg';
import React, { useEffect, useState } from 'react';
import { useStyles } from './platforms.styles';
import classNames from 'classnames';
import AccountDropdown from '../../components/accountDropdown';
import CardSkeleton from '../../components/cardSkeleton';
import { getUserData } from '../../services/auth.service';
import { checkToken } from '../../components/useAuth';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const platforms = [
  {
    id: 1,
    value: 'Edge 2.0',
    image: signalLogoBlue,
    height: '34px',
    redirectLink: [],
    activeLink: true,
  },
  {
    id: 2,
    value: 'Set-leads',
    image: signalLogoOrange,
    height: '34px',
    redirectLink: [],
    activeLink: true,
  },
  {
    id: 3,
    image: ADP,
    value: 'ADP',
    height: '34px',
    redirectLink: [
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=WFN',
        platform: 'ADP Workforce Now',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMWFN',
        platform: 'ADP Workforce Now - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=CLOWFN',
        platform: 'ADP Workforce Now Next Generation',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMADPI',
        platform: 'ADP DataCloud - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMFEDSSO',
        platform: 'ADP Federated Single Sign On - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMECE',
        platform: 'ADP Health Compliance - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMRECR',
        platform: 'ADP Recruiting - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=RECRUITINGE',
        platform: 'ADP Recruiting Employee',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=RECRUITINGM',
        platform: 'ADP Recruiting Manager',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMCRT',
        platform: 'ADP Reporting - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMSMART',
        platform: 'ADP SmartCompliance - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMSPTCNTR',
        platform: 'ADP Support Center - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMTCREDIT',
        platform: 'ADP Tax Credit Screening - Admin',
        permission: 'ADP',
      },

      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMDOCM',
        platform: 'CD-ROM Encryption - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMGLI',
        platform: 'General Ledger Interface - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=REDBOX',
        platform: 'MyADP',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADMPQV',
        platform: 'Payroll QuickView - Admin',
        permission: 'ADP',
      },
      {
        url: 'https://mobifed.adp.com/oauth/client/v2/136c4ff588c1738389242dc2f2dc18eafbb3d6da63154d4ef5ab43fd9497ddef?run=true&adpwf=true&appid=ADM401K',
        platform: 'Retirement Services - Admin',
        permission: 'ADP',
      },
    ],
    activeLink: true,
  },
  {
    id: 4,
    image: hubspot,
    value: 'Hubspot-CBX',
    height: '34px',
    redirectLink: [
      {
        url: 'https://app.hubspot.com/login/beta?loginPortalId=2721732',
        platform: 'Hubspot-CBX',
        permission: 'Hubspot-CBX',
      },
    ],
    activeLink: true,
  },
  {
    id: 5,
    value: 'Hubspot-PBX',
    image: hubspot,
    height: '34px',
    redirectLink: [
      {
        url: 'https://app.hubspot.com/login/beta?loginPortalId=23883111',
        platform: 'Hubspot-PBX',
        permission: 'Hubspot-PBX',
      },
    ],
    activeLink: true,
  },
  {
    id: 6,
    value: 'Tracktik',
    image: trackTik,
    height: '34px',
    redirectLink: [
      {
        url: 'https://edge.teamsignal.com/auth/open-id?locale=en_us',
        platform: 'Tracktik',
        permission: 'Tracktik',
      },
    ],
    activeLink: true,
  },
  {
    id: 7,
    image: asana,
    value: 'Asana',
    height: '34px',
    redirectLink: [],
    activeLink: true,
  },
  {
    id: 8,
    value: 'Talentreef',
    image: talentReef,
    height: '34px',
    redirectLink: [],
    activeLink: true,
  },
  {
    id: 9,
    image: ms365,
    value: '',
    height: '34px',
    redirectLink: [],
    activeLink: true,
  },
  {
    id: 10,
    value: 'Sage',
    image: sage100,
    height: '34px',
    redirectLink: [],
    activeLink: true,
  },
  {
    id: 11,
    image: screenCloud,
    value: '',
    height: '34px',
    redirectLink: [],
    activeLink: true,
  },
];

const Platforms = () => {
  const classes = useStyles();
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState([]);
  const [permissionList, setPermissionList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedUrl([]);
  };

  const isLoggedIn = checkToken();

  platforms.forEach(platform => {
    platform.activeLink = platform.redirectLink.length > 0;
  });

  const getUserInfoData = async () => {

    try {
      const response = await getUserData();
      if (response ) {
        const userInfo = await response.data;
        const permissionsArray = userInfo?.user?.permissions;
        setPermissionList(permissionsArray);
        const filteredArray = platforms.filter(obj =>
          permissionsArray.includes(obj.value)
        );

        setSelectedPlatform(filteredArray);
        setIsLoading(false);
      }
    } catch (error) {

      setIsLoading(false);
      toast.error(error?.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };



  useEffect(() => {
    if (isLoggedIn) {
      getUserInfoData();
    } else {
      history.push('/');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectionCheck = item => {
    if (item.redirectLink.length === 1) {
      window.open(item.redirectLink[0].url, '_blank');
    } else if (item.redirectLink.length > 1) {
      const selectedArray = item.redirectLink.filter(obj =>
        permissionList.includes(obj.permission)
      );

      setSelectedUrl(selectedArray);
      handleOpen();
    } else {
    }
  };

  const handleLogout = () => {
    history.push('/logout');
    // localStorage.clear();
    // logout({ logoutParams: { returnTo: window.location.origin } });
    // window.location.reload();
  };

  return (
    <Box className={classes.platforms}>
      <AccountDropdown handleLogout={handleLogout} />

      <Box className={classes.platformsContent}>
        <img
          src={signalLogo}
          alt="Signal"
          className={classes.platformsContentImage}
        />
        <Box>
          <Typography variant="h1" className={classes.platformsInfoTitle}>
            Choose a Platform
          </Typography>
          <Typography variant="body2" className={classes.platformsInfoText}>
            Redirect to any platform via SSO
          </Typography>
        </Box>
        <Box className={classes.platformsCards}>
          {isLoading ? (
            <CardSkeleton noOfCards={6} />
          ) : (
            <>
              {selectedPlatform.map(item => {
                return (
                  <Box
                    onClick={() => redirectionCheck(item)}
                    key={item.id}
                    className={
                      item.activeLink
                        ? classes.platformsCard
                        : classNames(
                            classes.platformsCard,
                            classes.platformsCardNonActive
                          )
                    }
                  >
                    <img
                      style={{ height: item.height }}
                      src={item.image}
                      alt="Platform"
                      className={classes.platformsCardImage}
                    />
                    <Typography
                      variant="subtitle1"
                      className={classes.platformsCardText}
                    >
                      {item.value}
                    </Typography>
                    <RedirectIcon className={classes.platformsRedirectIcon} />
                  </Box>
                );
              })}
            </>
          )}
        </Box>
      </Box>
      <URLModal
        open={open}
        handleClose={handleClose}
        permissionList={permissionList}
        selectedUrl={selectedUrl}
      />
    </Box>
  );
};
export default Platforms;

const URLModal = ({ open, handleClose, selectedUrl }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.urlModalContent}>
        <Button
          onClick={handleClose}
          disableRipple
          className={classes.closeBtn}
        >
          <CloseIcon />
        </Button>

        <Typography className={classes.urlModalTitle} variant="h3">
          Choose a Login
        </Typography>
        <Typography className={classes.urlModalText} variant="body2">
          Select an account to login
        </Typography>

        <Box className={classes.urlModalLinks}>
          {selectedUrl.map(url => (
            <>
              <a
                href={url.url}
                className={classes.urlModalLink}
                target="_blank"
                rel="noreferrer"
                onClick={handleClose}
              >
                {url.platform}
              </a>
            </>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};
