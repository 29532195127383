import { toast } from 'react-toastify';
import { getHttpRequest, postHttpRequest } from '../helper/axios';
import { throwAPIError } from '../utils/throwAPIError';
// import { isObjectEmpty } from '../helper/utilityFunctions';

// eslint-disable-next-line no-undef
export const authServiceEndPoint = process.env.REACT_APP_USER;
// Login API
export const authLogin = async () => {
  try {
    // const data = await postHttpRequest(`${authServiceEndPoint}/auth/login`, postData);
  } catch (e) {
    toast.error('Something went wrong!', {
      position: 'top-right',
      autoClose: 3000,
    });
  }
};
export const forgetPassword = async postData => {
  try {
    if (!postData.user.email) {
      throw Error();
    }
    return await postHttpRequest(
      `${authServiceEndPoint}/auth/forgot_password`,
      postData
    );
  } catch (e) {
    return throwAPIError(e);
  }
};

export const getUserData = async () => {
  try {
    return await getHttpRequest(`${authServiceEndPoint}/configs/permissions`);
  } catch (e) {
    return throwAPIError(e);
  }
};
export const logoutCall = async () => {
  try {
    return await postHttpRequest(`${authServiceEndPoint}/auth/logout`);
  } catch (e) {
    return throwAPIError(e);
  }
};
