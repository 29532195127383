import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import LoaderComponent from '../loader/index';
import { logoutCall } from '../../services/auth.service';

const Logout = () => {
  const { logout } = useAuth0();

  const logoutUser = async () => {
    await logoutFromBackend();
    localStorage.clear();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const logoutFromBackend = async () => {

    try {
      const response = await logoutCall();
      if (response && response.statusCode === 200) {
        /**
         * show toast if wanted
         */
      }
    } catch (error) {
      /**
       * show error in the corresponding field
       * parse errors in array format and set them in errorMessages
       * setErrorMessages(error)
       */
    }
  };

  useEffect(() => {
    logoutUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <LoaderComponent size={50} color={'primary'} label={'Loading'} />
    </>
  );
};

export default Logout;
