import Platforms from './pages/platforms';
import {ThemeProvider} from '@mui/material';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Auth0ProviderWithNavigate} from './Auth0Provider';
import {theme} from './theme';
import {ToastContainer} from 'react-toastify';
import {Redirect} from 'react-router-dom/cjs/react-router-dom.min';
import {checkToken} from './components/useAuth';
import Login from './pages/login';
import ForgotPassEmail from './pages/forgotPassword';
import NotFound from './pages/notFound';
import Logout from './components/logout';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';

import {createBrowserHistory} from 'history';


import {ClickAnalyticsPlugin} from '@microsoft/applicationinsights-clickanalytics-js';

import {
    AppInsightsErrorBoundary,
    ReactPlugin,
    withAITracking,
} from '@microsoft/applicationinsights-react-js';


const browserHistory = createBrowserHistory({basename: window.location.origin});

let clickPluginInstance = new ClickAnalyticsPlugin();
let clickPluginConfig = {
    autoCapture: true,
};

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        connectionString: `instrumentationKey=${process.env.REACT_APP_INSIGHTS}`,
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderExcludedDomains: [
            '*.auth0.com',
            '*.googleapis.com',
            '*.blob.core.windows.net',
        ],
        extensions: [reactPlugin, clickPluginInstance],

        [clickPluginInstance.identifier]: clickPluginConfig,

        extensionConfig: {
            [reactPlugin.identifier]: {history: browserHistory},
        },
    },
});

appInsights.loadAppInsights();


function App() {
    return (
        <ThemeProvider theme={theme}>
            <AppInsightsErrorBoundary
                onError={() => <p>Something went wrong. Please refresh your page.</p>}
                appInsights={reactPlugin}
            >
                <Auth0ProviderWithNavigate>
                    <Router>
                        <Switch>
                            <Route exact path="/">
                                <Login/>
                            </Route>

                            <Route exact path="/logout">
                                <Logout/>
                            </Route>

                            <Route exact path="/forgot-password">
                                <ForgotPassEmail/>
                            </Route>
                            <ProtectedRoute exact path="/app">
                                <Platforms/>
                            </ProtectedRoute>
                            <Route path="*">
                                <NotFound/>
                            </Route>
                        </Switch>
                    </Router>
                    <ToastContainer/>
                </Auth0ProviderWithNavigate>

            </AppInsightsErrorBoundary>
        </ThemeProvider>
    );
}


export default withAITracking(reactPlugin, App);


const ProtectedRoute = ({children, ...rest}) => {
    const isLoggedIn = checkToken();
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                        }}
                    />
                )
            }
        />
    );
};



