import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import bannerImage from '../../assets/images/platforms/login.png';
import { ReactComponent as Logo } from '../../assets/images/platforms/signal-logo.svg';
import { useEffect, useState } from 'react';
import { useStyles } from './login.styles';
import { getUserData } from '../../services/auth.service';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import LoaderComponent from '../../components/loader';
import { toast } from 'react-toastify';
import { checkToken } from '../../components/useAuth';

export default function Login() {
  const {
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    error,
    logout,
    isLoading: load,
  } = useAuth0();
  const [isShow, setShow] = useState(load);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const errorState = queryParams.get('error');
  const isLoggedIn = checkToken();

  const currentYear = new Date().getFullYear();

  const getToken = async () => {
    try {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      localStorage.setItem('accessToken', accessToken);
      setTimeout(() => {
        getPermission();
      }, 1000);
    } catch (error) {
      toast.error(error?.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  const getPermission = async () => {
    try {
      setIsLoading(true);

      const response = await getUserData();
      if (response) {
        const getUserData = await response.data;

        localStorage.setItem('userData', JSON.stringify(getUserData));

        history.push('/app');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/app');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setShow(load);
  }, [load]);

  useEffect(() => {
    if (errorState && error) {
      logout({
        logoutParams: {
          returnTo: window.location.origin + '?loginError=' + errorState,
        },
      });
    }

    if (isAuthenticated) {
      getToken();
    } else {
      // loginWithRedirect();
    }
  }, [isAuthenticated, errorState, logout, error]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderForm = (
    <Box component="form" className={classes.wrapperContent}>
      <Button
        className={classes.loginButton}
        variant="primary"
        type="button"
        onClick={loginWithRedirect}
      >
        Login with SSO
      </Button>
    </Box>
  );

  if (isLoading || isShow) {
    return isLoading || isShow ? (
      <LoaderComponent size={100} color={'primary'} label={'Loading'} />
    ) : (
      ''
    );
  }

  return (
    <>
      <Box className={classes.mainFormLogin}>
        <Box className={classes.bannerItem}>
          <img
            src={bannerImage}
            alt="Signals Security"
            className={classes.bannerImage}
          />
        </Box>
        <Box className={classes.contentItem}>
          <Box className={classes.innerContent}>
            <Box className={classes.logoImage}>
              <Logo />
            </Box>
            <Box className={classes.welcomeContent}>
              <Box className={classes.loginHeadingWrapper}>
                <Typography variant="h1" className={classes.loginHeading}>
                  Welcome to Signal
                </Typography>
                <Typography className={classes.manageText} variant="body2">
                  Hassle free login with Single Sign On on multiple platforms
                  with just one click.
                </Typography>
              </Box>
              {renderForm}
            </Box>
            <Typography variant="body3" className={classes.copyRightText}>
              @{currentYear} Signal. All rights reserved.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
